import React from 'react';
// import logo from './logo.svg';
import './App.css';

// import * as Survey from "survey-react";
import { Survey, ReactSurveyModel } from 'survey-react';
import "survey-react/survey.css";
const axios = require('axios').default;



const json = require('./model.json');

function App() {

  const model = new ReactSurveyModel(json);

  const onComplete = async (result: any) => {

    const options = {
      method: 'POST',
      headers: { 'Authorization': 'CWB082c7jR40gDvtvpxLP8eYbY8AuMMr5qGqt9l8' },
      data: result.data,
      url: 'https://4nyxg1t3ff.execute-api.eu-west-1.amazonaws.com/prod-primaria/'
    };

    console.log("Complete! ", result);
    const axiosResult = await axios(options)
    console.log('result: ', axiosResult)
  }

  return (
    <div className="App">
      <a href="https://ideab3.it">
        <img src="https://www.ideab3.it/wp-content/uploads/2019/02/237x75.png" width="237" height="75" style={{maxHeight:'75px', height:"auto"}} alt="IDEA B3 Logo"/>
      </a>
      <div style={{width:'80%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'justify'}}>
        <Survey style={{textAlign: 'left'}} model={model} onComplete={onComplete} />
      </div>
    </div>
  );
}

export default App;
